/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
new WOW({offset: 160 }).init()
  // Custom 
  var stickyToggle = function(sticky, scrollElement, stickyTop) {

    if (scrollElement.scrollTop() > stickyTop){
      sticky.addClass("is-sticky");
    }
    else{
      sticky.removeClass("is-sticky");
    }
  };
  $('[data-toggle="tooltip"]').tooltip()

  // Find all data-toggle="sticky-onscroll" elements
  $('[data-toggle="sticky-onscroll"]').each(function() {
    var sticky = $(this);
    var stickyTop = sticky.offset().top;
    sticky.addClass('sticky');
    // Scroll & resize events
    $(window).on('scroll.sticky-onscroll resize.sticky-onscroll', function() {
      stickyToggle(sticky, $(this), stickyTop);
    });
    
    // On page load
    stickyToggle(sticky, $(window), stickyTop);
  });



  $(function(){
    $('.toggle-nav').click(function(){
      $(this).toggleClass('collapsed');
      if($('body').hasClass('show-nav')){
        $('body').removeClass('show-nav').addClass('hide-nav');
        setTimeout(function(){
          $('body').removeClass('hide-nav');},500);
      }else{
        $('body').removeClass('hide-nav').addClass('show-nav');
      }
      return false;
    });
  });

  $(document).keyup(function(e){if(e.keyCode==27){if($('body').hasClass('show-nav')){$('body').removeClass('show-nav').addClass('hide-nav');setTimeout(function(){$('body').removeClass('hide-nav');},500);}else{$('body').removeClass('hide-nav').addClass('show-nav');}}});
  
},
finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);



})(jQuery); // Fully reference jQuery after this point.

